import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const ProductSubmenu = ({ name, className, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);

  const items = [
    {
      title: 'AI Avatar Generator',
      href: 'https://voge.metabrixlab.com/',
    },
    {
      title: '3D Character Generator',
      href: 'https://ultronai.me/',
    },
    {
      title: 'Conversational Avatars',
      href: 'https://app.ultronai.me/',
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const setDropdown = () => {
    setIsOpen(true);
  };
  const resetDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div className={`${className} relative`} onClick={toggleDropdown} onMouseEnter={setDropdown} onMouseLeave={resetDropdown}>
      <div className={`flex items-center cursor-pointer`}>
        <a>{name}</a>
        <span
          className={`transition-transform duration-200 -mr-4 ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
          style={{ width: '24px', height: '24px' }}
        >
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff00">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z" fill="#ffffff50"></path>
          </svg>
        </span>
      </div>

      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
          className={`${!isMobile&&"absolute p-5 -ml-5"} `}
        >
        <div className={`flex flex-col ${isMobile&&"mt-8 gap-8 ml-2"}  ${!isMobile && "border border-white/15 rounded-md "}bg-db p-2`}>
            {items.map((link, i) => (
                <a
                target={link.title === 'Docs' ? "_blank" : "_self"}
                key={i}
                href={link.href}
                className={`capitalize ${!isMobile&&"p-2"} rounded-md text-white hover:bg-gray-700 whitespace-nowrap`}
                >
                {link.title}
                </a>
            ))}
        </div>
        </motion.div>
      )}
    </div>
  );
};

export default ProductSubmenu;
