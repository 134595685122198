import React from "react";
import Hero from "../components/Hero"
import EasyCustom from "../components/EasyCustom"
import MotionReady from "../components/MotionReady"
import ExpressionReady from "../components/ExpressionReady"
import Generate3d from "../components/Generate3d"
import DeveloperFriendly from "../components/DeveloperFriendly"
import Logoclient from "../components/Logoclient"
import Testimonials from "../components/Testimonials"
import Cta from "../components/Cta"
import BenefitsCard from "../components/BenefitsCard";
import Working from "../components/Working";


const Home = () => {
    return (
        <>
            <Hero />
            <Logoclient />
            <Working />
            <EasyCustom />
            <MotionReady />
            <ExpressionReady />
            <BenefitsCard />
            <Generate3d />
            <DeveloperFriendly />
            <Testimonials />
            <Cta />
            
        </>
    )
}
export default Home;