import React from 'react';
import personalise from '../assets/images/heathcare.png';
import agents from '../assets/images/agents.png';
import seamless from '../assets/images/seamless.png';
import commerce from '../assets/images/commerce.png';
import npc from '../assets/images/npc.png';
import crowd from '../assets/images/crowd.png';
import benefits from '../assets/images/benefits.svg';
import bgDesign from '../assets/images/design.png';

const data = [
    {
        id: 1,
        url: personalise,
        title: "Healthcare Agent",
        description:"Ultron AI is a revolutionary platform that uses AI to enhance patient communication and streamline hospital operations, improving patient experience.",
        style: { top: '8%', left: '4%' },
    },
    {
        id: 2,
        url: npc,
        title: "Create Intelligent NPCs",
        description: "A cutting-edge platform for generating AI-powered, lifelike 3D characters, perfect for immersive experiences and interactive storytelling.",
        style: { top: '12%', right: '4%' },
    },
    {
        id: 3,
        url: crowd,
        title: "Customer Engagement for Banking & Insurance",
        description: "Seamlessly enhancing customer interactions, automating workflows, and driving efficiency for financial institutions.",
        style: { top: '35.5%', left: '8%' },
    },
    {
        id: 4,
        url: seamless,
        title: "Seamless Integration",
        description: "Ultron AI integrates seamlessly via its SDK and APIs, enabling AI agents for real-time interaction, automation, and enhanced user experiences without workflow disruptions.",
        style: { top: '41%', right: '8%' },
    },
    {
        id: 5,
        url: agents,
        title: "Personalized Conversations with AI Agents/Trainer",
        description: "Delivering tailored interactions and guidance for users. Experience seamless and intuitive engagement with AI-driven dialogues.",
        style: { top: '65%', left: '4%' },
    },
    {
        id: 6,
        url: commerce,
        title: "Revolutionizing Content Generation",
        description: "Ultron AI empowers creators with lifelike, customizable AI co-hosts that engage audiences, enhancing interaction, and unlocking new monetization opportunities.",
        style: { top: '65%', right: '4%' },
    },
];

function Card({ item }) {
    return (
        <div
            className={`rounded-xl card flex bg-[#081D28] lg:absolute max-h-[30%] mx-auto my-2 text-white w-full lg:w-[40%]  sm:w-full md:w-4/5  md:my-6 `}
            style={item.style}
        >
            <div className='image w-[42%] relative h-[20%]'>
                <img src={item.url} className='w-full h-full object-cover' alt={item.title} />
            </div>
            <div className='flex flex-col w-[60%] text-left justify-center p-4'>
                <h1 className='text-lg text-[#00FFFF] font-[Lalezar] md:text-2xl'>{item.title}</h1>
                <p className='text-xs w-full font-[Inter] md:text-lg'>{item.description}</p>
            </div>
        </div>
    );
}

function BenefitsCard() {
    return (
        <div className="benefits-container relative w-screen overflow-hidden min-h-[1200px] p-6 lg:flex lg:pt-8 pt-0 justify-center items-start  xl:px-16 lg:px-12 md:px-8 sm:px-4 max-w-[1400px] mx-auto">
            <h1 className='text-center text-5xl text-[#00FFFF] font-[Lalezar] mb-8'>Benefits</h1>
            <img className='absolute top-[7%] right-[50] left-[50] hidden md:block' src={benefits}
                style={{
                    height: '870px',
                    width: 'auto',
                }}
                alt="" />
            {data.map((item) => (
                <Card key={item.id} item={item} />
            ))}
            <img className='absolute top-[80%] left-[10%] hidden md:block' src={bgDesign} alt="" />
        </div>
    );
}

export default BenefitsCard;
