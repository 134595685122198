"use client";
import { Discord } from "./svg.js";
import { motion } from "framer-motion";
import React from "react";
import Avatar from "../assets/logos/user.png";
import brand1 from "../assets/brands/brand-1.svg";
import brand2 from "../assets/brands/brand-2.svg";
import brand3 from "../assets/brands/brand-3.svg";
import brand4 from "../assets/brands/brand-4.svg";
import brand5 from "../assets/brands/brand-5.svg";
import brand6 from "../assets/brands/brand-6.svg";
import brand7 from "../assets/brands/brand-7.svg";
import brand8 from "../assets/brands/brand-8.svg";
import brand9 from "../assets/brands/brand-9.svg";
import brand10 from "../assets/brands/brand-10.svg";
import brand11 from "../assets/brands/brand-11.svg";
import brand12 from "../assets/brands/brand-12.png";
import brand13 from "../assets/brands/brand-13.png";
import crowd from '../assets/images/bal.jpeg';

const brandArray=[brand1 ,brand2,brand3,brand6,brand7,brand8,brand9,brand10,brand4,brand5,brand11,brand12,brand13]
export default function Comm() {
  const Tools = [
    {
      name: "DISCORD",
      link: "https://discord.com/invite/FXzz3mFSWY",
    },
    {
      name: "ZIMENSION 3D",
      link: "https://app.zimension3d.com/",
    },
    {
      name: "UNITY",
      link: "https://docs.ultronai.me/unity",
    },
    {
      name: "UNREAL ENGINE",
      link: "https://www.unrealengine.com/en-US/download",
    },
    {
      name: "BLENDER",
      link: "https://www.blender.org/download/",
    },
  ];

  return (
    <div className="xl:p-16 lg:p-12 md:p-8 p-4 md:mt-10 mt-12 ">
      <h4
        className=" text-center marker:  b-head la"
        style={{ letterSpacing: "0.05em" }}
      >
        Our <span className="text-lb">Partners</span> and{" "}
        <span className="text-lb">Affiliations</span>
      </h4>
      <div className="overflow-hidden flex items-center gap-[20px] justify-center feedback-main mt-10">
        <div className="feedback-brand-loop  md:flex gap-4 hidden ">
          {brandArray.concat(brandArray).map((src,i) => {
            return (
              <div
                key={i}
                className="max-w-[350px] w-full min-h-[125px] rounded-xl bg-[#2B333D] flex items-center justify-center shadow-lg shadow-black"
              >
                <motion.img
                  // src={`/assets/brands/brand-${i + 1}.svg`}
                  src={src}
                  className=""
                />
              </div>
            );
          })}
        </div>
        <div className=" grid  md:hidden grid-cols-2 gap-4 ">
          {brandArray.map((src,i) => {
            return (
              <div
                key={i}
                className="max-w-[140px] p-2 w-full min-h-[120px] rounded-xl bg-[#2B333D] flex items-center justify-center shadow-lg shadow-black"
              >
                <motion.img
                  // src={`/assets/brands/brand-${i + 1}.svg`}
                  src={src}
                  className=""
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="w-full max-w-full mt-20 md:max-w-4xl mx-auto grid sm:grid-cols-2 grid-cols-1 gap-8 items-center justify-center ">
        <div className="flex sm:flex-row flex-col items-center justify-center sm:text-start text-center gap-1">
          <Discord size={64} fill={"#fff"} />
          <h5
            className="text-sm sub-head"
            style={{
              lineHeight: 1.1,
            }}
          >
            600+ <br />
            Discord Members
          </h5>
        </div>

        <div className=" flex sm:flex-row flex-col items-center justify-center sm:text-start text-center gap-1">
          <img
            src={Avatar}
            className="w-auto h-16"
            alt="8000+ Avatars Generated"
          />
          <h5
            className="text-sm sub-head"
            style={{
              lineHeight: 1.1,
            }}
          >
            8000+ <br />
            Avatar Generations
          </h5>
        </div>
      </div> */}

      <h5 className="mt-20 text-3xl text-center text-lb sub-head">
        Integration
      </h5>
      <div
        className={`rounded-xl card flex bg-[#081D28] mx-auto my-2 text-white w-full lg:w-[40%] sm:w-full md:w-4/5 md:my-6`}
        style={{ height: 'auto' }}
      >
        <div className='image w-[42%] h-auto md:w-[40%] md:h-[18%] lg:w-[30%] lg:h-[15%] flex items-center justify-center'>
          <img src={crowd} className='w-full h-full object-cover rounded-xl' alt={"Integration"} />
        </div>
        <div className='flex flex-col w-[60%] text-left justify-center p-4 md:w-[58%] lg:w-[70%]'>
                    <h1 className='text-sm text-[#00FFFF] font-[Lalezar] md:text-xl lg:text-2xl sm:mb-0'>
            <a 
              href="https://app.ultronai.me/chat/?avatarId=6797712e1df463ea8ce8c032" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="underline"
            >
              Heathcare Agent: Belenus Champion Hospital
            </a>
          </h1>
          <p className='text-xs w-full font-[Inter] md:text-sm lg:text-lg sm:mb-0'>Ultron AI is a revolutionary platform that uses AI to enhance patient communication and streamline hospital operations, improving patient experience and efficiency.</p>
        </div>
      </div>
      <h5 className=" mt-20 text-3xl text-center text-lb sub-head">
        Try UltronAI on these platforms
      </h5>

      <div className="mt-10 w-full flex flex-wrap justify-center md:space-x-4 gap-4 mx-auto content-center">
        {Tools.map((tool, i) => {
          console.log(tool.name)
          return (
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={tool.link}
              key={i}
            >
              <div className="w-full md:min-w-56 min-w-36 h-auto px-4 py-10 rounded-xl bg-[#2B333D] flex flex-col gap-3 items-center justify-center shadow-lg shadow-black">
                <motion.img
                  src={`/assets/logos/${tool.name}.svg`}
                  className="h-[50px]"
                />
                <sub className="text-center">{tool.name}</sub>
              </div>
            </a>
          );
        })}
        <br />
      </div>
      {/* <div className="btn-container w-1/3 mx-auto flex items center justify-center  my-6">
        <button
          onClick={() => {
            window.open("https://ultronai.me/", "_blank");
          }
          }
          className="w-full rounded-xl my-4 min-w-[220px] bg-gray-900 py-4" style={{
            boxShadow: "inset cyan 0px 0px 30px -12px",
          }}>TRY ULTRON FOR FREE</button>
      </div> */}
    </div>
  );
}
